html {
    height: 100%;

    body {
        height: 100%;
        margin: 0;
        background-color: #333333;
        display: grid;
        grid-template-columns: 500px 1fr;
        font-family: sans-serif;
    }

    aside#panel {
        background-color: #1E1E1E;
        color: #FFFFFF;
        display: grid;
        grid-template-rows: auto auto 1fr auto auto;

        header h1 {
            font-size: 1.5em;
            text-align: center;
            background-color: #000000;
            margin: 0;
            padding: 0.5em;
        }

        .dropdown {
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;

            background-color: #3C3C3C;
            color: #fff;

            li {

                a {
                    display: inline-block;
                    color: white;
                    text-align: center;
                    padding: 10px 16px;
                    text-decoration: none;
                    font-size: 14px;
                    cursor: pointer;
                }

                /* Used to position the sub dropdown */
                position: relative;

                /* Change background color of list item when being hovered */
                &:hover {
                    &>a {
                        background-color: #505050;
                    }

                    /* Show the direct sub dropdown when hovering the list item */
                    &>ul {
                        display: block;
                    }
                }
            }

            /* The sub dropdown */
            ul {
                background-color: #252526;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                display: none;
                left: 0;
                position: absolute;
                top: 100%;
                list-style-type: none;
                margin: 0;
                padding: 0;
                min-width: 200px;

                li {
                    &.title {
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #ddd;
                        padding: 10px 0 0 10px;
                    }

                    &.separator {
                        border: 0;
                        height: 1px;
                        background-color: #444;
                        margin: 4px 0;
                    }

                    a {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 8px 16px;
                        text-align: left;
                        display: block;

                        &:hover {
                            background-color: #094771;
                        }

                        &.subdropdown::after {
                            content: "›";
                            float: right;
                            font-size: 24px;
                            font-weight: bold;
                            margin-top: -6px;
                        }
                    }
                }

                /* The second level sub dropdown */
                ul {
                    left: 100%;
                    position: absolute;
                    top: -35px;
                }
            }
        }

        #editor {
            padding: 20px;

            #editor-content {
                width: 100%;
                height: 100%;
                resize: none;
                font-family: monospace;
                padding: 1em;
                box-sizing: border-box;
                margin: auto;
                display: block;
                background-color: #1E1E1E;
                color: #FFFFFF;
            }
        }

        details {
            border-top: 3px solid #333333;
            font-size: 0.8em;

            summary {
                text-transform: uppercase;
                margin: 0;
                cursor: pointer;
                padding: 1em;
                font-size: 1em;

                .count {
                    font-weight: bold;
                    background-color: #4D4D4D;
                    padding: 0.1em 0.6em;
                    border-radius: 0.8em;
                }

                .clear {
                    // Button
                    float: right;
                    width: 28px;
                    height: 28px;
                    margin-top: -6px;
                    margin-right: 4px;
                    font-size: 20px;
                    padding: 0;
                    line-height: 20px;
                    border: none;
                    background-color: #333;
                    color: #bbb;
                    cursor: pointer;
                    transition: background-color 0.3s, color 0.3s;

                    &:hover {
                        background-color: #e74c3c;
                        color: #fff;
                    }
                }
            }

            .list {
                margin: 0 1em 1em 1em;
                padding: 0 0.5em;
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                li {
                    width: 100%;
                    padding: 0.5em;
                    box-sizing: border-box;
                    background-color: #4D4D4D;
                    color: #FFFFFF;
                    cursor: pointer;
                    transition: background-color 0.1s ease-in-out;

                    &:hover {
                        background-color: #717171;
                    }

                    &.active {
                        background-color: #094771;
                    }

                    .time {
                        float: right;
                        font-size: 0.9em;
                        color: #ddd;
                        line-height: 1.6em;
                    }
                }
            }
        }

        #errors ul,
        #warnings ul,
        #stack ul {
            max-height: 200px;
            overflow-y: auto;
        }

        #warnings li::before {
            content: "⚠️ ";
        }

        #errors li::before {
            content: "❌ ";
        }
    }

    main#viewer {
        display: grid;
        grid-template-rows: 1fr auto;

        #visualizer {
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                margin-bottom: 0px;
            }
        }

        #controls {
            background-color: #1E1E1E;
            padding: 1.5em;
            color: white;
            text-align: center;

            .buttons {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .slider-container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 6px;
                }

                button {
                    margin: 0 0.1em;
                    padding: 0.5em 0.7em;
                    font-size: 1em;
                    border-radius: 0.5em;
                    border: 0;
                    background-color: #4D4D4D;
                    color: #FFFFFF;
                    cursor: pointer;
                    transition: background-color 0.2s ease-in-out;

                    &:disabled {
                        background-color: #333;
                        cursor: not-allowed;
                    }

                    &.hidden {
                        display: none;
                    }

                    &:hover:not(:disabled) {
                        background-color: #717171;
                    }

                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}