html {
  height: 100%;
}

html body {
  height: 100%;
  background-color: #333;
  grid-template-columns: 500px 1fr;
  margin: 0;
  font-family: sans-serif;
  display: grid;
}

html aside#panel {
  color: #fff;
  background-color: #1e1e1e;
  grid-template-rows: auto auto 1fr auto auto;
  display: grid;
}

html aside#panel header h1 {
  text-align: center;
  background-color: #000;
  margin: 0;
  padding: .5em;
  font-size: 1.5em;
}

html aside#panel .dropdown {
  color: #fff;
  background-color: #3c3c3c;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

html aside#panel .dropdown li {
  position: relative;
}

html aside#panel .dropdown li a {
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 10px 16px;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

html aside#panel .dropdown li:hover > a {
  background-color: #505050;
}

html aside#panel .dropdown li:hover > ul {
  display: block;
}

html aside#panel .dropdown ul {
  min-width: 200px;
  background-color: #252526;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 8px 16px #0003;
}

html aside#panel .dropdown ul li.title {
  text-transform: uppercase;
  color: #ddd;
  padding: 10px 0 0 10px;
  font-size: 14px;
}

html aside#panel .dropdown ul li.separator {
  height: 1px;
  background-color: #444;
  border: 0;
  margin: 4px 0;
}

html aside#panel .dropdown ul li a {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
  display: block;
}

html aside#panel .dropdown ul li a:hover {
  background-color: #094771;
}

html aside#panel .dropdown ul li a.subdropdown:after {
  content: "›";
  float: right;
  margin-top: -6px;
  font-size: 24px;
  font-weight: bold;
}

html aside#panel .dropdown ul ul {
  position: absolute;
  top: -35px;
  left: 100%;
}

html aside#panel #editor {
  padding: 20px;
}

html aside#panel #editor #editor-content {
  width: 100%;
  height: 100%;
  resize: none;
  box-sizing: border-box;
  color: #fff;
  background-color: #1e1e1e;
  margin: auto;
  padding: 1em;
  font-family: monospace;
  display: block;
}

html aside#panel details {
  border-top: 3px solid #333;
  font-size: .8em;
}

html aside#panel details summary {
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  padding: 1em;
  font-size: 1em;
}

html aside#panel details summary .count {
  background-color: #4d4d4d;
  border-radius: .8em;
  padding: .1em .6em;
  font-weight: bold;
}

html aside#panel details summary .clear {
  float: right;
  width: 28px;
  height: 28px;
  color: #bbb;
  cursor: pointer;
  background-color: #333;
  border: none;
  margin-top: -6px;
  margin-right: 4px;
  padding: 0;
  font-size: 20px;
  line-height: 20px;
  transition: background-color .3s, color .3s;
}

html aside#panel details summary .clear:hover {
  color: #fff;
  background-color: #e74c3c;
}

html aside#panel details .list {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 1em 1em;
  padding: 0 .5em;
  list-style: none;
  display: flex;
}

html aside#panel details .list li {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background-color: #4d4d4d;
  padding: .5em;
  transition: background-color .1s ease-in-out;
}

html aside#panel details .list li:hover {
  background-color: #717171;
}

html aside#panel details .list li.active {
  background-color: #094771;
}

html aside#panel details .list li .time {
  float: right;
  color: #ddd;
  font-size: .9em;
  line-height: 1.6em;
}

html aside#panel #errors ul, html aside#panel #warnings ul, html aside#panel #stack ul {
  max-height: 200px;
  overflow-y: auto;
}

html aside#panel #warnings li:before {
  content: "⚠️ ";
}

html aside#panel #errors li:before {
  content: "❌ ";
}

html main#viewer {
  grid-template-rows: 1fr auto;
  display: grid;
}

html main#viewer #visualizer {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

html main#viewer #visualizer h2 {
  margin-bottom: 0;
}

html main#viewer #controls {
  color: #fff;
  text-align: center;
  background-color: #1e1e1e;
  padding: 1.5em;
}

html main#viewer #controls .buttons {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

html main#viewer #controls .buttons .slider-container {
  flex-direction: column;
  margin-bottom: 6px;
  display: flex;
}

html main#viewer #controls .buttons button {
  color: #fff;
  cursor: pointer;
  background-color: #4d4d4d;
  border: 0;
  border-radius: .5em;
  margin: 0 .1em;
  padding: .5em .7em;
  font-size: 1em;
  transition: background-color .2s ease-in-out;
}

html main#viewer #controls .buttons button:disabled {
  cursor: not-allowed;
  background-color: #333;
}

html main#viewer #controls .buttons button.hidden {
  display: none;
}

html main#viewer #controls .buttons button:hover:not(:disabled) {
  background-color: #717171;
}

html main#viewer #controls .buttons button img {
  filter: invert();
}

/*# sourceMappingURL=index.6d338c4b.css.map */
